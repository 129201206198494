<template>
  <el-select
    v-model="locationModel"
    :default-first-option="defaultFirstOption"
    :disabled="disabled"
    :loading="loading"
    :placeholder="placeholder ?? $t('COMMON.LOCATION')"
    :popper-class="popperClass"
    :remote-method="getLocations"
    @change="locationChanged"
    autocomplete="new-password"
    collapse-tags
    filterable
    :remote="!optionsValueIsSet"
  >
    <slot>
      <span class="first-choose" v-if="showLabel">
        <span class="first-choose-label">{{ $t("COMMON.LOCATION") }}</span>
        <!-- <span @click="selectAll" class="first-choose-select">
          {{ $t("VEHICLES.SELECT_ALL") }}
        </span> -->
      </span>
    </slot>
    <el-option v-if="allowNone" :value="null" :label="$t('LOCATIONS.NONE')" />
    <el-option
      v-for="location in locations"
      :key="location.id"
      :value="location.id"
      :label="`${location.name} ${
        location.organization ? '- ' + location.organization?.name : ''
      }`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "location-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder of input",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    location: {
      type: String,
      default: null,
      description: "Location id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOrganizationNull: {
      type: Boolean,
      default: null,
      description: "Filter only null organizations",
    },
    defaultFirstOption: {
      type: Boolean,
      default: false,
    },
    popperClass: {
      type: String,
      default: null,
    },
    optionsValue: {
      type: Array,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      locationModel: this.location,
      locations: {},
      loading: false,
    };
  },

  setup() {},

  created() {
    this.getLocations(null, this.location);
  },

  computed: {
    optionsValueIsSet() {
      return !!this.optionsValue && Array.isArray(this.optionsValue);
    },
  },

  methods: {
    async getLocations(query = null, id = null) {
      if (this.optionsValueIsSet) {
        this.loadOptions(this.optionsValue);
        return;
      }

      try {
        this.loading = true;
        let params = {
          sort: "-updated_at",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { with_ids: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
          include: "organization",
          fields: {
            organizations: "name",
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterOrganizationNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: null,
            },
          };
        }

        await this.$store.dispatch("locations/list", params);
        const locationsArr = await this.$store.getters["locations/list"];
        this.loadOptions(locationsArr);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    loadOptions(optionsArr) {
      this.locations = {};
      optionsArr.forEach((location) => {
        this.locations[location.id] = location;
      });

      if (this.defaultFirstOption && !this.locationModel && !this.disabled) {
        this.locationModel = optionsArr[0]?.id ?? null;

        this.$nextTick(() => {
          this.locationChanged(this.locationModel);
        });
      }
    },

    locationChanged(locationId) {
      this.$emit("locationChanged", locationId, this.locations[locationId]);
    },
  },

  watch: {
    location(location) {
      if (location) {
        this.locationModel = location;
        if (location !== this.locationModel) {
          this.getLocations(null, location);
        }
      } else {
        this.locationModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getLocations();
    },
    filterOrganizationNull(filterOrganization) {
      this.getLocations();
    },
    filterReseller(filterReseller) {
      this.getLocations();
    },
    filterResellerNull(filterReseller) {
      this.getLocations();
    },
    optionsValue(filterReseller) {
      this.getLocations();
    },
  },
};
</script>
