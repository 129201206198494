import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

const includes = [
  "organization",
  "creator",
  "vehicle",
  "customer",
  "approver",
  "assignedBy",
  "completedBy",
  "canceledBy",
  "startedBy",
  "inspection",
  "estimation",
  "validatedBy",
  "endedBy",
  "items",
  "assignedToUsers",
  "assignedToRoles",
  "allowedLocations",
];

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/repairs`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/repairs/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let inspection = jsona.deserialize(response.data);
      delete inspection.links;
      return inspection;
    });
}

function add(inspection) {
  const payload = jsona.serialize({
    stuff: inspection,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/repairs`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(inspection) {
  const payload = jsona.serialize({
    stuff: inspection,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/repairs/${inspection.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/repairs/${id}`, options);
}

function complete(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/repairs/${id}/complete?include=${includes.join(",")}`,
      data,
      options
    )
    .then((response) => {
      let request = jsona.deserialize(response.data);
      delete request.links;
      return request;
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/repairs/${id}/cancel?include=${includes.join(",")}`, options)
    .then((response) => {
      let request = jsona.deserialize(response.data);
      delete request.links;
      return request;
    });
}

function assign(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/repairs/${id}/assign?include=${includes.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function validate(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/repairs/${id}/validate?include=${includes.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function start(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/repairs/${id}/start?include=${includes.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function takeOver(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/repairs/${id}/take-over?include=${includes.join(",")}`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function getCount(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/repairs/list/count`, options)
    .then((response) => response.data);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  complete,
  cancel,
  assign,
  validate,
  start,
  takeOver,
  getCount,
};
