<template>
  <div>
    <div
      style="
        background-color: red;
        color: white;
        z-index: 100;
        text-align: center;
        position: relative;
        display: none;
      "
    >
      {{ previousGridRoute?.fullPath }} > {{ previousPathRoute?.fullPath }} >
      {{ currentRoute?.fullPath }}
    </div>
    <router-view v-if="appConfig"></router-view>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      title: process.env.VUE_APP_NAME,
      meta: (function () {
        return [
          {
            name: "keywords",
            content: process.env.VUE_APP_NAME,
          },
          {
            name: "description",
            content: process.env.VUE_APP_NAME,
          },
        ];
      })(),

      link: [{ rel: "icon", type: "image/png", href: "/favicon.png" }],
      script: (function () {})(),
    };
  },

  computed: {
    ...mapGetters({
      appConfig: "appConfig/appConfig",
      currentRoute: "routes/currentRoute",
      previousPathRoute: "routes/previousPathRoute",
      previousGridRoute: "routes/previousGridRoute",
      history: "routes/history",
    }),
  },

  data() {
    return {};
  },

  async mounted() {
    swal.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
        popup: "global-popup",
      },
      onOpen: () => {
        swal.showLoading();
      },
    });

    if (this.$store.getters["isAuthenticated"]) {
      await this.$store.dispatch("profile/me");
      try {
        await this.$store.dispatch("organizationOptions/get");
      } catch (e) {}
      
      try {
        await this.$store.dispatch("locations/initState");
        await this.$store.dispatch("organizations/initState");
      } catch (error) {}

      window.addEventListener("popstate", this.onHistoryChange);
      window.addEventListener("pushstate", this.onHistoryChange);
      window.addEventListener("replacestate", this.onHistoryChange);
    }
  },

  methods: {
    onHistoryChange(event) {
      console.log(event);
    },
  },

  watch: {
    appConfig: {
      handler: function (val) {
        if (val) {
          swal.close();
        }
      },
      deep: true,
    },

    history: {
      handler: function (val) {
        // console.log(this.history);
      },
      deep: true,
    },
  },
};
</script>
