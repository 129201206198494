export default {
  OPTIONS_LIST: "Liste des paramètres",

  MODULE: "Module",
  NAME: "Nom",
  VALUE: "Valeur",
  MODULE_GENERAL: "Général",
  MODULE_PURCHASES: "Achats",
  MODULE_ESTIMATION: "Estimations",
  MODULE_INSPECTION: "Inspections",
  MODULE_REPAIR: "Réparations",
  MODULE_SALES: "Ventes",
  OPTION_DOCUMENT_FOOTER: "Pied de page des document",

  OPTION_DEFAULT_TAXES_GROUP: "Groupe de taxes par défaut",
  OPTION_DEFAULT_ESTIMATION_ITEM_HOURLY_RATE:
    "Taux horaire par défaut des éléments de l’estimation",
  OPTION_DEFAULT_INSPECTION_COMPLETED_CALLBACK_URL:
    "URL de callback après complétion d’une inspection",
  OPTION_DEFAULT_PENDING_APPROVE_ESTIMATION_CALLBACK_URL:
    "URL de callback après mis en attente d’approbation de l’estimation",
  OPTION_DEFAULT_ESTIMATION_APPROVED_CALLBACK_URL:
    "URL de callback après approbation de l’estimation",
  OPTION_DEFAULT_REPAIR_COMPLETED_CALLBACK_URL:
    "URL de callback après completion d’une réparation",
};
