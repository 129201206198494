import service from "@/store/services/locations-service";
import localStorageService from "../services/local-storage-service";

const KEY_GLOBAL_LOCATION = "GLOBAL_LOCATION";

export const state = {
  list: {},
  location: {},
  meta: {},
  globalLocation: null,
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, location) => {
    state.location = location;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_GLOBAL_LOCATION: (state, location) => {
    state.globalLocation = location;
  },
};

export const actions = {
  initState({ commit, dispatch, rootGetters }) {
    let location = localStorageService.get(KEY_GLOBAL_LOCATION);

    const me = rootGetters["profile/me"];

    if (me?.restrict_to_locations) {
      const allowedLocationsIds =
        me?.allowedLocations?.map((location) => location.id) ?? [];

      if (!allowedLocationsIds.includes(location?.id)) {
        location = me?.allowedLocations[0] ?? null;
      }
    }

    if (location) {
      dispatch("setGlobalLocation", location);
    }
  },

  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((location) => {
      commit("SET_RESOURCE", location);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((location) => {
      commit("SET_RESOURCE", location);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((location) => {
      commit("SET_RESOURCE", location);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },

  attachUsers({ commit, dispatch }, params) {
    return service.attachUsers(params).then((location) => {
      commit("SET_RESOURCE", location);
    });
  },

  detachUsers({ commit, dispatch }, params) {
    return service.detachUsers(params).then((location) => {
      commit("SET_RESOURCE", location);
    });
  },

  setGlobalLocation({ commit, dispatch }, location) {
    const organization = location?.organization;
    dispatch("organizations/setGlobalOrganization", organization, {
      root: true,
    });
    commit("SET_GLOBAL_LOCATION", location);
    localStorageService.save(KEY_GLOBAL_LOCATION, location);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  location: (state) => state.location,
  globalLocation: (state) => state.globalLocation,
  dropdown: (state) => {
    return state.list.map((location) => ({
      id: location.id,
      name: location.name,
    }));
  },
};

const locations = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default locations;
