import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      me: "profile/me",
      globalOrganization: "organizations/globalOrganization",
      globalLocation: "locations/globalLocation",
    }),

    currentUserRestrictLocations() {
      return this.me?.restrict_to_locations ? this.me?.allowedLocations : null;
    },

    isRestrictToOneLocation() {
      return (
        Array.isArray(this.currentUserRestrictLocations) &&
        this.currentUserRestrictLocations.length == 1
      );
    },
  },
};
