export default {
  REPAIRS_LIST: "Liste des réparations",
  REPAIR: "Réparation",
  ADD_REPAIR: "Ajouter une réparation",
  VIEW_REPAIR: "Voir la réparation",
  EDIT_REPAIR: "Modifier une réparation",
  STOCK_NIV: "Stock / NIV",
  CLIENT_WORK_ORDER: "Client / Work Order",
  MODIFY: "Modification",
  DAYS: "Jour(s)",
  REPAIR_STATUS_PENDING: "à assigner",
  REPAIR_STATUS_IN_PROGRESS: "En cours",
  REPAIR_STATUS_DRAFT: "Brouillon",
  REPAIR_STATUS_CANCELED: "Annulée",
  REPAIR_STATUS_COMPLETED: "Complétée",
  REPAIR_STATUS_ASSIGNED: "à faire",
  REPAIR_STATUS_VALIDATED: "Validée",
  REPAIR_STATUS_APPROVED: "Approuvée",
  REPAIR_STATUS_REVIEWED: "Revue",
  REPAIR_STATUS_DENIED: "Refusée",
  CANCEL_REPAIR: "Annulée",
  CANCEL_THIS_REPAIR: "Voulez-vous annuler cette réparation ?",
  REPAIR_CANCELED: "Réparation annulée",
  CANCELED_BY: "Annulée par",
  CANCELED_AT: "Annulée le",
  SHOW_REPAIR: "Voir la réparation",
  VALIDATE_THIS_REPAIR: "Voulez-vous valider cette réparation ?",
  REPAIR_VALIDATED: "Réparation validée",
  REPAIR_ASSIGNED: "Réparation assignée",
  REPAIR_UPDATED: "Réparation modifiée",
  REPAIR_ADDED: "Réparation ajoutée",
  COMPLETED_BY: "Completer par",
  CREATOR: "Créer par",
  DELETE_THIS_REPAIR: "Voulez-vous supprimer cette réparation ?",
  REPAIR_DELETED: "Réparation supprimée",
  CREATED_AT: "Créer le",
  ASSIGN_REPAIR: "Assigner la réparation",
  REPAIRERS: "Mécaniciens",
  ASSIGN_TO_REPAIRERS: "Assigner aux mécaniciens",
  ADD_REPAIRER: "Ajouter un mécanicien",
  ASSIGN_THIS_REPAIR: "Voulez-vous assigner cette réparation ?",
  REPAIR_ALREADY_EXIST: "Une réparation est déjà en cours pour ce véhicule",
  VALIDATED_BY: "Valider par",
  VALIDATED_AT: "Valider le",
  ASSIGNED_BY: "Assignée par",
  ASSIGNED_TO: "Assignée à",
  ASSIGNED_TO_GROUP: "Assignée au groupe",
  ASSIGNED_AT: "Assignée le",
  ASSIGNED_TO_ME: "Assignées à moi",
  REPAIR_DETAIL: "Détail des réparations",
  REPAIR_ITEM_PART_NUMBER: "Numéro de pièce",
  REPAIR_CLIENT: "Client",
  REPAIR_DEPARTMENT: "Département",
  REPAIR_ALL_DEPARTMENTS: "Départements",
  REPAIR_ASSIGN_TO: "Assignée à",
  REPAIR_TYPE: "Type de réparation",
  REPAIR_DETAIL: "Détails de la réparation",
  REPAIR_ITEM: "Item",
  REPAIR_ITEM_DETAIL: "Détail",
  REPAIR_ITEM_STATE: "État",
  REPAIR_ITEM_PART: "Pièce",
  REPAIR_ITEM_PART_NUMBER: "Numéro de pièce",
  REPAIR_ITEM_MANUAL_LABOR: "Main-d'oeuvre",
  REPAIR_ITEM_TIME: "Temps",
  REPAIR_ITEM_HOURLY_RATE: "Taux horaire",
  REPAIR_ITEM_APPROVED: "Approuver",
  REPAIR_ITEM_REVIEW: "Réviser",
  REPAIR_ITEM_REFUSED: "Refuser",
  REPAIR_ITEM_STATUS_APPROVED: "Approuver",
  REPAIR_ITEM_STATUS_REVIEWED: "À réviser",
  REPAIR_ITEM_STATUS_DENIED: "Refuser",
  REPAIR_ITEM_CONDITION_TO_REPLACE: "À remplacer",
  TO_REPLACE: "À remplacer",
  TO_REPAIR: "À réparer",
  REPAIRED: "Réparé",

  START_REPAIR: "Démarrer la réparation",
  START_THIS_REPAIR: "Voulez-vous démarrer la réparation ?",
  REPAIR_STARTED: "La réparation a commencée",
  STARTED_AT: "Débuté le",
  STARTED_BY: "Débutée par",

  END_REPAIR: "Terminer la réparation",
  END_THIS_REPAIR: "Voulez-vous terminer la réparation?",
  REPAIR_ENDED: "Réparation terminée",
  ENDED_AT: "Terminée à",
  COMPLETED_AT: "Complétée à",
  ENDED_BY: "Terminée par",

  REPAIR_ITEM_ADDED: "Élément ajouté",
  REPAIR_ITEM_DELETED: "Élément supprimé",
  OTHER_WORK_ON_REPAIR: "Quelqu’un d’autre travaille déjà sur cette réparation",
  TAKE_OVER_REPAIR: "S’approprier la réparation",
  TAKE_OVER_THIS_REPAIR:
    "Cette réparation est en cours par {name}. \n Voulez-vous vous l’approprier?",
  YES_TAKE_OVER: "Oui, m’approprier la réparation",
  REPAIR_TOOK_OVER: "Appropriation <u>effectuée</u>",
  SHOW_REPAIR: "Voir la réparation",
  DELETE_THIS_ITEM_FROM_REPAIR:
    "Voulez-vous supprimer cet élément de la réparation ?",
  ALL: "Toutes",
};
